
  .timelinev-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
}

.timelinev-container::after {
    /* background-color: #e17b77; */
    background-image: linear-gradient(to bottom, #25bdb3 , #fccc08);
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;
}

.timelinev-item {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 50%;
}

.timelinev-item:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
}

.timelinev-item-content {
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); */
    /* border-radius: 5px; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* padding: 15px; */
    position: relative;
    width: 400px;
    max-width: 70%;
    text-align: right;
}

.timelinev-item-content-left {
    border-right: 2px solid #000;
    padding-right: 0.5rem;
}

.timelinev-item-content-right {
    border-left: 2px solid #000;
    padding-left: 0.5rem;
}

.timelinev-item-content-left::after {
    content: ' ';
    background-color: #fff;
    /* box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2); */
    position: absolute;
    right: -9px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    border-right: 2px solid #000;
    border-top: 2px solid #000;
}

.timelinev-item-content-right::after {
    content: ' ';
    background-color: #fff;
    position: absolute;
    left: -9px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
}

.timelinev-item:nth-child(odd) .timelinev-item-content {
    text-align: left;
    align-items: flex-start;
}

.timelinev-item:nth-child(odd) .timelinev-item-content::after {
    right: auto;
    left: -9px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timelinev-item-content .tag {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    position: absolute;
    text-transform: uppercase;
}

.timelinev-item:nth-child(odd) .timelinev-item-content .tag {
    left: auto;
    right: 5px;
}

.timelinev-item-content time {
    color: #777;
    font-size: 12px;
    font-weight: bold;
}

.timelinev-item-content p {
    font-size: 16px;
    line-height: 24px;
    margin: 15px 0;
    max-width: 250px;
}

.timelinev-item-content a {
    font-size: 14px;
    font-weight: bold;
}

.timelinev-item-content a::after {
    content: ' ►';
    font-size: 12px;
}

.timelinev-item-content .circle {
    /* background-color: #fff; */
    /* border: 3px solid #e17b77; */
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: -42px;
    width: 20px;
    height: 20px;
    z-index: 100;
    background-image: url("../../assets/dot.png");
    background-size: 100% 100%;
}

.timelinev-item:nth-child(odd) .timelinev-item-content .circle {
    right: auto;
    left: -41px;
}

@media only screen and (max-width: 1023px) {
    .timelinev-item-content {
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .timelinev-item-content,
    .timelinev-item:nth-child(odd) .timelinev-item-content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
    }

    .timelinev-item-content .tag {
        width: calc(100% - 10px);
        text-align: center;
    }

    .timelinev-item-content time {
        margin-top: 20px;
    }

    .timelinev-item-content a {
        text-decoration: underline;
    }

    .timelinev-item-content a::after {
        display: none;
    }
}