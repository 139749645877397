.aboutSectionOne {
  /* background-color: #0e2d39; */
  background-image: url("../../assets/team.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 100vh; */
  width: 100%;
  /* padding: 1rem; */
}

.abtsecimageheight {
  margin: 0;
  background: #00000052;
  height: 100vh;
}

.founderImage {
  background-image: url("../../assets/founders.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  /* padding: 1rem; */
  z-index: -1;
}

.aboutSectionTwo {
  background-color: #0e2d39;
}

.asthead {
  text-align: center;
  margin: 4rem 25%;
  color: #fff;
  font-size: 45px;
  font-weight: bold;
}

.gradientText {
  background: linear-gradient(to right, #25bdb3, #fccc08);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.astos {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.team-card {
  height: auto;
  /* width: 15rem; */
  border-radius: 1rem;
  margin: 1rem 0rem;
  cursor: pointer;
}

.team-img {
  height: auto;
  border: 1rem 1rem 0 0;
}

.team-name {
  font-size: 1.2rem;
  font-weight: 700;
}

.in-icon {
  background: #fff;
  color: #000;
  font-size: 1.5rem;
  padding: 0.6rem 0.7rem;
  border-radius: 50%;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  box-shadow: 1px 1px 5px 1px #555;
}

.midsection {
  padding: 2rem 6rem;
}

.vision {
  /* height: 50vh; */
  background: #fccc08;
  border-radius: 1rem;
  margin: 2rem;
  box-shadow: 1px 1px 5px 0 #aaa;
  color: #0e2d39;
  font-size: 1.2rem;
  font-weight: 500;
}

.mission {
  /* height: 90vh; */
  background: #98c44f;
  border-radius: 1rem;
  margin: 2rem;
  box-shadow: 1px 1px 5px 0 #aaa;
  color: #0e2d39;
  font-size: 0.9rem;
  font-weight: 500;
}

.m-ul {
  margin-top: 1rem;
}

.m-li {
  margin-left: 1.3rem;
  margin-top: 0.5rem;
}

.timeline-img {
  height: 15rem;
  position: absolute;
  bottom: -5rem;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: -1;
}

.vis-img {
  height: 18rem;
}

.mis-img {
  height: 18rem;
}

.mis-1 {
  padding: 5%;
}

.v-1 {
  padding: 3rem;
}

.tm-sec {
  position: relative;
  padding: 7rem;
}

.tm-c {
  margin: 15rem 0;
}

.th-1 {
  bottom: 25%;
}

@media screen and (max-width: 576px) {
  .asthead {
    /* margin: 4rem 15%; */
    margin: 3rem 1rem 0;
    font-size: 1.5rem;
  }

  .vis-img {
    height: 15rem;
  }

  .mis-img {
    height: 15rem;
  }

  .midsection {
    padding: 0;
  }

  .tm-sec {
    padding: 1rem;
  }

  .tm-c {
    margin: 5rem 0 10rem 0;
  }

  .th-c {
    padding-left: 0.5rem;
  }

  .th-1 {
    margin-top: 0.5rem;
    font-size: 14px;
    bottom: 0;
  }

  .th-2 {
    font-size: 10px;
  }

  .abtsecimageheight {
    height: 30vh;
  }
}

@media screen and (max-width: 360px) {
  .asthead {
    /* margin: 4rem 15%; */
    margin: 3rem 1rem 0;
    font-size: 1.5rem;
  }

  .vis-img {
    height: 15rem;
  }

  .mis-img {
    height: 15rem;
  }

  .midsection {
    padding: 0;
  }

  .tm-sec {
    padding: 1rem;
  }

  .tm-c {
    margin: 5rem 0 10rem 0;
  }

  .th-c {
    padding-left: 0.5rem;
  }

  .th-1 {
    font-size: 14px;
    margin-top: 0.5rem;
    bottom: 0;
  }

  .th-2 {
    font-size: 10px;
  }

  .abtsecimageheight {
    height: 30vh;
  }
}
