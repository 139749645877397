.chead{
    background-color: #0e2d39;
    background-image: url("../../assets/contactbg.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    padding: 1rem;
}

.con-tainer{
    padding: 5rem 8rem;
}

.react-tabs__tab-list {
    border-bottom: none !important;
}

.react-tabs__tab {
    padding-left: 0 !important;
}

.react-tabs__tab--selected {
    border: none !important;
    background-image: -webkit-gradient(linear, 0 100%, from(#25bdb3), to(#fccc08)) !important;
    background-image: -webkit-linear-gradient(180deg, #25bdb3, #fccc08) !important;
    background-image: -moz-linear-gradient(180deg, #25bdb3, #fccc08) !important;
    background-image: -o-linear-gradient(180deg, #25bdb3, #fccc08);
    background-image: linear-gradient(90deg, #25bdb3, #fccc08) !important;
    -moz-background-size: 100% 3px !important;
    background-size: 100% 3px !important;
    background-position: 0 100% !important;
    background-repeat: no-repeat !important;
}

.accordion {
    border: none !important;
}

.accordion__button:before {
    display: inline-block;
    position: absolute !important;
    right: 1rem !important;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg) !important;
}

.accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before {
    transform: rotate(225deg) !important;
}

.accordion__button {
    background-color: #fff !important;
}

.accordion__panel {
    color: #0F2D39 !important;
    font-size: 0.9rem !important;
}

.c-bird{
    background-color: #0e2d39;
    padding: 2rem 5rem;
    color: #fff;
    position: relative;
}

.c-bird-img{
    height: 17rem;
}

.c-ul{
    padding: 2rem 0;
    font-size: 0.8rem;
}

.co-img{
    height: 1rem;
    margin-right: 0.5rem;
}

.co-img1{
    height: 0.7rem;
    margin-right: 0.5rem;
}

.drop-box{
    background-color: #fccc08;
    border-radius: 1rem;
    padding: 1.5rem;
    position: absolute;
    top: 5rem;
    right: 15rem;
    width: 25rem;
    z-index: 1;
}

.msg-inp{
    margin: 0.5rem 0 !important;
}

.msg-txtb{
    border-radius: 0.5rem !important;
    margin: 0.5rem 0 !important;
}

.s-btn{
    display: block;
    margin: auto;
    padding: 0.3rem 2rem !important;
}

@media screen and (max-width: 768px) { 
    .con-tainer{
        padding: 4rem 2rem 4rem 2rem;
    }

    .drop-box{
        top: 16rem;
        right: 15rem;
        width: 20rem;
    }
}

@media screen and (max-width: 576px) { 
    .con-tainer{
        padding: 4rem 1rem 4rem 1rem;
    }
    
    .react-tabs__tab {
        display: block !important;
        border: 1px solid transparent;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 12px;
        width: fit-content !important;
        cursor: pointer;
    }

    .con-s{
        padding: 0 2rem;
    }

    .c-bird{
        padding: 2rem;
    }

    .drop-box{
        background-color: #fccc08;
        border-radius: 1rem;
        padding: 1rem;
        position: absolute;
        top: 17rem;
        right: 2rem;
        width: 19rem;
    }

    .c-bird-img {
        height: 9rem;
        margin-top: -5rem;
        margin-right: -2rem;
    }
}

@media screen and (max-width: 360px) { 

    .con-tainer{
        padding: 4rem 0rem 4rem 0rem;
    }

    .react-tabs__tab {
        display: block !important;
        border: 1px solid transparent;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 12px;
        width: fit-content !important;
        cursor: pointer;
    }

    .con-s{
        padding: 0 2rem;
    }

    .c-bird{
        padding: 2rem;
    }

    .drop-box{
        background-color: #fccc08;
        border-radius: 1rem;
        padding: 1rem;
        position: absolute;
        top: 17rem;
        right: 2rem;
        width: 19rem;
    }

    .c-bird-img {
        height: 9rem;
        margin-top: -5rem;
        margin-right: -2rem;
    }
}