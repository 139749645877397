.header-bg {
  background: #0e2d39;
  padding: 1rem 0.5rem;
}

.logo-img {
  margin: 0 2rem;
  /* width: 13rem; */
  width: 10rem;
  cursor: pointer;
}

.left-img {
  display: none;
}

.mid-img {
  display: block;
}

.nlink {
  color: #f0f2f3;
  display: block;
  padding: 0.8rem 2rem;
}

.nlink:hover {
  text-decoration: none;
  color: #fccc08;
}

.nav-btn {
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  border: none;
}

.nbtnlink {
  color: #000;
  font-weight: 600;
}

.nbtnlink:hover {
  text-decoration: none;
  color: #f0f2f3;
}

.gradient-bg {
  background-image: linear-gradient(to right, #25bdb3, #fccc08);
}

.top {
  margin-top: 4.5rem;
}

.header1-img {
  height: 32rem;
}

.head1 {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
}

.head2 {
  color: #fff;
  font-size: 14px;
}

.headtext {
  padding: 7rem 7rem 4rem 7rem;
}

.txt-grad {
  background: -webkit-linear-gradient(to right, #25bdb3, #fccc08);
  background: -o-linear-gradient(to right, #25bdb3, #fccc08);
  background: -moz-linear-gradient(to right, #25bdb3, #fccc08);
  background: linear-gradient(to right, #25bdb3, #fccc08);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* footer */

.footer-bg {
  background: #0e2d39;
  /* height: 80vh; */
}

.foot {
  margin: 0;
  padding-top: 10rem;
}

.footer-sec1 {
  color: #fff;
  padding: 0rem 8rem;
}

.footer-sec2 {
  color: #fff;
  padding: 0rem;
  padding-left: 0;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-top: 0.2rem;
  letter-spacing: 0.6px;
}

.get-app {
  line-height: 1;
  margin-top: 3.2rem;
}

.t-pd {
  padding: 8rem 4rem;
}

.getitnow {
  padding: 3rem 1rem;
  font-size: 3rem;
  margin: 0;
  font-weight: 700;
}

@media screen and (max-width: 1200px) {
  .header-bg {
    background: #0e2d39;
    padding: 1rem;
  }

  .logo-img {
    margin: 0;
    width: 10rem;
  }

  .nlink {
    color: #f0f2f3;
    display: block;
    padding: 0.5rem 1rem;
  }

  .left-img {
    display: block;
  }

  .mid-img {
    display: none;
  }

  .top {
    margin-top: 5rem;
  }

  .header1-img {
    height: 30rem;
  }

  .head1 {
    color: #fff;
    font-size: 40px;
  }

  .headtext {
    padding: 7rem 4rem 4rem 5rem;
  }

  .getitnow {
    padding: 1rem 4rem;
    font-size: 2.5rem;
    margin: 0;
    font-weight: 700;
  }

  /* .footer-sec1{
    padding: 4rem 8rem;
  } */
}

@media screen and (max-width: 992px) {
  .header1-img {
    height: 25rem;
  }

  .head1 {
    color: #fff;
    font-size: 30px;
  }

  .head2 {
    color: #fff;
    font-size: 14px;
  }

  .headtext {
    padding: 2rem;
  }

  .getitnow {
    padding: 1rem 4rem;
    font-size: 2.5rem;
    margin: 0;
    font-weight: 700;
  }
}

@media screen and (max-width: 768px) {
  .header1-img {
    height: 18rem;
  }

  .head1 {
    color: #fff;
    font-size: 23px;
  }

  .head2 {
    color: #fff;
    font-size: 15px;
  }

  .headtext {
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 576px) {
  .header1-img {
    height: 10rem;
    /* float: right; */
  }

  .head1 {
    color: #fff;
    font-size: 15px;
  }

  .headtext {
    padding: 0;
  }

  .get-app {
    margin-top: 1rem;
  }

  .link {
    margin: 0.5rem 0rem;
    height: 2.5rem;
    float: right;
  }

  .footer-bg {
    height: auto;
  }

  .foot {
    margin: 0;
    padding-top: 7rem;
  }

  .footer-sec1 {
    padding: 1rem 2rem;
  }

  .footer-sec2 {
    padding: 0 2rem;
  }

  .t-pd {
    padding: 10rem 0rem;
  }

  .headerappicon {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .header1-img {
    /* height: 10rem; */
    height: 15rem;
    /* float: right; */
  }

  .head1 {
    color: #fff;
    font-size: 13px;
  }

  .head1 {
    color: #fff;
    font-size: 20px;
  }

  .headtext {
    padding: 0;
  }

  .get-app {
    margin-top: 1rem;
  }

  .link {
    margin: 0.5rem 0rem;
    height: 2.5rem;
    float: right;
  }

  .footer-bg {
    height: auto;
  }

  .foot {
    margin: 0;
    padding-top: 7rem;
  }

  .footer-sec1 {
    padding: 1rem 2rem;
  }

  .footer-sec2 {
    padding: 0 2rem;
  }

  .t-pd {
    padding: 10rem 0rem;
  }

  .headerappicon {
    display: none;
  }
}

.privacy {
  line-height: 25px;
  /* text-align: justify; */
}

p.terms_text {
  padding: 15px 0;
}

.headerappicon {
  color: white;
  font-size: 1.8rem;
  margin-right: 1rem;
}

.headerappicon:hover {
  color: #fccc08;
}

.arrowup {
  z-index: 99999;
  position: fixed;
  bottom: 0;
  margin: 1rem;
  right: 0;
  border-radius: 50%;
  border: none;
  height: 3rem;
  width: 3rem;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  background-image: linear-gradient(to right, #25bdb3, #fccc08);
  outline: none;
}