.cahead{
    background-color: #0e2d39;
    background-image: url("../../assets/career.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    padding: 1rem;
}

.ph-1{
    font-size: 0.9rem;
    margin-top: 1rem;
}

.h-1{
    margin: 1rem;
}

.rider{
    padding: 2rem;
    box-shadow: 1px 1px 15px 1px #777;
    border-radius: 1rem;
}

.jb-c{
    height: 95% !important;
}

.col-c{
    padding-bottom: 1rem;
}

.wh-1{
    text-align: center;
    display: block;
    margin: auto;
    padding: 5rem 15%;
    padding-bottom: 0rem;
}

.wh-p{
    padding: 3rem;
}

.dwus {
    padding: 2rem 5rem 5rem 5rem !important;
}

@media screen and (max-width: 768px) { 

    .wh-p{
        padding: 2rem;
    }
    
}

@media screen and (max-width: 576px) { 

    .wh-p{
        padding: 1rem;
    }

    .dwus {
        padding: 2rem 0 5rem 0 !important;
    }
    
}

@media screen and (max-width: 360px) {

    .wh-p{
        padding: 1rem;
    }

    .dwus {
        padding: 2rem 0 5rem 0 !important;
    }

}