.voteme-btn{
    background: #fccc08;
    border-radius: 2rem;
    color: #0e2d39;
    padding: 0.5rem 3rem;
    outline: none;
    font-size: 1rem;
    font-weight: 500;
}

.ccard{
    /* height: 18rem; */
    font-size: 20px;
    margin: 1rem;
}

.card-highlight{
    border-radius: 1.2rem; 
    border: 6px solid transparent; 
    background: linear-gradient(to bottom, #fccc08, #25bdb3) border-box; 
}

.bg1{
    background-image: url("../../assets/votebg.png");
}

.vt-img{
    object-fit: cover;
    border-radius: 1rem 1rem 0 0;
}

.cb{
    background: #fff;
    padding: 1rem;
    border-radius: 0 0 1rem 1rem;
}

.inp{
    border-radius: 0.5rem;
}

.vote-select{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #555;
    opacity: 0.2;
    text-align: center;
    top: 0px;
    border-radius: 1rem;
}

.vote-tick{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}