.sectionOne {
  background-color: #0e2d39;
  background-image: url("../../assets/headerbg.png");
  background-size: contain;
  background-repeat: no-repeat;
  /* height: 130vh; */
  width: 100%;
  padding: 1rem;
}

.slider-section {
  /* height: 100vh; */
  width: 100%;
}

.slider-bgimgs {
  width: 100%;
  height: 80vh;
  position: relative;
}

.slider-txt {
  position: absolute;
  top: 10rem;
  left: 5rem;
  width: 50%;
}

.slider-img {
  position: absolute;
  top: 1rem;
  right: 5rem;
  width: 50%;
  text-align: right;
}

.sl-img {
  height: 30rem;
}

.sl-img1 {
  height: 20rem;
}

.sl-dot-c {
  margin-bottom: 3rem;
  cursor: pointer;
}

.sl-dot-a1 {
  height: 1.5rem;
  margin: 0.3rem;
}

.sl-dot-a2 {
  height: 0.8rem;
  margin: 0.6rem;
}

.sectionTwo {
  /* background-color: #0e2d39; */
  background-image: url("../../assets/headerbg2.png");
  background-size: contain;
  background-repeat: repeat;
  /* height: 120vh; */
  width: 100%;
  padding: 1rem;
}

.section-report {
  padding: 0 2rem 0 5rem;
}

.sectionThree {
  /* background-color: #0e2d39; */
  background-image: url("../../assets/testimonial.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  padding: 1rem;
}

.sectionFour {
  background-image: url("../../assets/headerbg3.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 54vh; */
  width: 100%;
  padding: 1rem;
  box-shadow: 1px 1px 5px 1px #737476;
  border-radius: 2rem;
}

.subscribe {
  text-align: center;
  padding: 1rem 4rem;
}

.subscribe-btn {
  width: 100%;
  padding: 0.5rem !important;
  margin: 1rem;
}

.sectionFive {
  background: #11bbc3;
  /* height: 38vh; */
  width: 100%;
  padding: 2rem 4rem;
  box-shadow: 1px 1px 5px 1px #ccc;
  border-radius: 2rem;
  box-shadow: 1px 1px 5px 0 #737476;
}

.sectionSix {
  background: #ffcc05;
  /* height: 130vh; */
  width: 100%;
}

.white-box {
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0px 0px 4px 0px #ccc;
}

.box1 {
  height: 19rem;
}

.box2 {
  height: 9rem;
  /* margin: 0.5rem; */
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
}

.box3 {
  height: 9rem;
  margin: 0.5rem;
}

.impacttext {
  padding: 5rem;
  padding-right: 0;
}

.p1 {
  font-size: 72px;
  margin: 0;
  font-weight: 800;
  margin-top: -1rem;
}

.p2 {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  margin-top: 0.5rem;
}

.p3 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  margin-top: -0.5rem;
  line-height: 1.2;
}

.p4 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-top: -0.3rem;
}

.p6 {
  font-size: 55px;
  margin: 0;
  font-weight: 700;
}

.testimonial {
  color: #000;
}

.testi1 {
  font-size: 45px;
  color: #0e2d39;
  font-weight: 700;
  margin-top: 5rem;
  margin-left: -2rem;
}

.testi2 {
  font-size: 14px;
  color: #0e2d39;
  padding: 5rem 3rem 3rem 5rem;
}

.vote-section {
  padding: 4rem 8rem;
  padding-bottom: 2rem;
}

.vote-img {
  height: 15rem;
}

.vote-txt {
  padding: 1rem 5rem;
  line-height: 1;
}

.vote-txt2 {
  font-size: 13px;
  color: #0e2d39;
  padding: 1rem 0;
  line-height: 1.2;
  letter-spacing: 0.3px;
}

.vote-btn {
  background: #0e2d39;
  padding: 0rem 2rem;
  outline: none;
}

.newsletter-section {
  width: 100%;
  padding: 2rem 8rem;
  position: absolute;
  top: -11rem;
}

.news2 {
  margin-top: 15rem;
  display: flex;
}

.input-box {
  border-radius: 2rem;
  width: 100%;
  border: none;
  padding: 0.6rem;
  padding-left: 1rem;
  font-size: 0.8rem;
  margin: 1rem;
}

.newsletter-head {
  font-size: 25px;
  color: #fff;
}

.newsletter-txt {
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: #fff;
}

.news-head {
  font-size: 2rem;
  color: #0e2d39;
  font-weight: 700;
  margin: 0rem 3rem 2rem 7rem;
}

.news-txt {
  font-size: 14px;
  color: #0e2d39;
  padding: 0 1rem 5rem 7rem;
}

.news-card {
  /* width: 16rem; */
  height: 25rem;
  border-radius: 1rem;
  box-shadow: 0 0 5px 0 #999;
  margin: 0 0.5rem;
}

.news-img {
  height: 11rem;
}

.link-section {
  background-image: url("../../assets/headerbg3.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 35vh; */
  width: 100%;
  /* padding: 2rem 4rem; */
  box-shadow: 1px 1px 5px 1px #ccc;
  border-radius: 2rem;
  box-shadow: 1px 1px 5px 0 #737476;
}

.link-side-img {
  height: 12rem;
  bottom: 0rem;
  position: absolute;
  left: 0rem;
  border-radius: 17%;
}

.link {
  margin: 5rem 1rem;
  height: 3.5rem;
}

/* testimonial balloon image css */

.myClip {
  /* display: inline-block; */
  margin: 2rem;
  background-color: #f1f1f1;
  background-image: url("../../assets/headerbg2.png");
  clip-path: url(#clipPath);
  background-size: contain;
}
.greenBaloon {
  position: absolute;
  z-index: 1;
}
.yellowBaloon {
  position: absolute;
  display: inline-block;
  left: 300px;
  top: 245px;
  z-index: -1;
}

.balloon {
  padding-top: 6rem;
  padding-left: 5rem;
}

.pn-btn {
  cursor: pointer;
  background: transparent;
  border: none;
  outline-color: #fff;
  color: #0e2d39;
}

.pn-btn:focus {
  outline: none;
}

.new-c {
  margin: 0;
  padding-bottom: 8rem;
}

.new-c1 {
  width: 40%;
  margin-top: 8rem;
}

.new-c2 {
  width: 60%;
  margin-top: 8rem;
  padding-left: 5rem;
}

/* testimonial balloon image css end */

@media screen and (max-width: 1200px) {
  .box1 {
    height: 13rem;
  }

  .box2 {
    height: 6rem;
    margin: 0.5rem;
    margin-top: 0;
  }

  .box3 {
    height: 6rem;
    margin: 0.5rem;
  }

  .link {
    margin:  5rem 0rem;
    height: 2.5rem;
  }
}

@media screen and (max-width: 992px) {
  .box1 {
    height: 13rem;
  }

  .box2 {
    height: 6rem;
    margin: 0.5rem;
    margin-top: 0;
  }

  .box3 {
    height: 6rem;
    margin: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .box1 {
    height: 13rem;
  }

  .box2 {
    height: 6rem;
    margin: 0.5rem;
    margin-top: 0;
  }

  .box3 {
    height: 6rem;
    margin: 0.5rem;
  }
}

.sl-h1 {
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.sl-bt-ps1 {
  position: absolute;
  left: 14%;
  top: 90%;
}

.sl-bt-ps2 {
  position: absolute;
  left: 10%;
  top: 60%;
}

.impact-img {
  padding: 5rem;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .box1 {
    height: 13rem;
  }

  .box2 {
    /* height: 6rem; */
    min-height: 9rem;
    margin: 0.5rem;
    margin-top: 0;
  }

  .box3 {
    height: 6rem;
    margin: 0.5rem;
  }

  .sectionOne {
    height: auto;
  }

  .sectionTwo {
    height: auto;
    background-size: auto;
  }

  .slider-section {
    height: auto;
  }

  .impacttext {
    padding: 0rem;
  }

  /* .impacttext1{
        width: 60%;
    } */

  .impact-img {
    padding: 0;
  }

  .headerimapactimg {
    height: 15rem;
  }

  .section-report {
    padding: 1rem;
  }

  .sectionThree {
    padding: 0 1rem;
  }

  .testi1 {
    font-size: 25px;
    margin-left: -1rem;
    margin-top: 0;
  }

  .testi2 {
    font-size: 14px;
    color: #0e2d39;
    /* width: 50%; */
    padding: 0;
    margin-top: 2rem;
    margin-left: -1rem;
  }

  .vote-section {
    padding: 1rem;
  }

  .vote-txt {
    padding: 1rem;
  }

  .vote-now {
    display: block;
    margin: auto;
    width: 100%;
  }

  .p3 {
    font-size: 35px;
  }

  /* .vote-txt2{
        width: 65%;
        margin-bottom: 0;
    } */

  /* .vote-now-img{
        text-align: right;
        position: absolute;
        top: 8rem;
        padding: 0;
    } */

  .vote-img {
    height: 9rem;
  }

  .newsletter-section {
    padding: 1rem;
  }

  .sectionFive {
    padding: 1rem;
    height: auto;
  }

  .subscribe {
    padding: 0;
  }

  .input-box {
    margin: 0.5rem 0;
  }

  .subscribe-btn {
    margin: 0;
    padding: 0.5rem 2rem;
  }

  .news-head {
    font-size: 30px;
    margin: 14rem 1rem 2rem 1rem;
  }

  .news-txt {
    padding: 0 1rem;
  }

  .link-side-img {
    height: 8rem;
    bottom: -15rem;
    position: absolute;
    left: 0rem;
    border-radius: 17%;
  }

  .p6 {
    font-size: 40px;
    margin-top: 1rem;
  }

  .link {
    margin: 0.5rem 0rem;
    height: 2.5rem;
    float: right;
  }

  .sectionSix {
    height: auto;
  }

  .news2 {
    margin-top: 1rem;
    margin-bottom: 10rem;
    flex-direction: column;
  }

  .news-card {
    width: auto;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 0 5px 0 #999;
    margin: 0.5rem;
  }

  /* slider */
  .slider-bgimgs {
    width: 100%;
    height: auto;
    position: relative;
  }

  .slider-txt {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: 50%;
  }

  .slider-img {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 50%;
    text-align: right;
  }

  .sl-h1 {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  .sl-h4 {
    font-size: 0.8rem;
  }

  .sl-p {
    font-size: 0.5rem;
  }

  .sl-img {
    height: 10rem;
  }

  .sl-img1 {
    height: 5rem;
  }

  .sl-dot-c {
    margin-bottom: 0rem;
  }

  .sl-dot-a1 {
    height: 1rem;
    margin: 0.3rem;
  }

  .sl-dot-a2 {
    height: 0.5rem;
    margin: 0.3rem;
  }

  .v-sec {
    display: flex;
    text-align: center;
  }

  .v-sec-1 {
    order: 2;
  }

  .v-sec-2 {
    order: 1;
  }

  .getitnow {
    padding: 1rem 2rem;
    font-size: 3rem;
    margin: 0;
    font-weight: 700;
  }

  .myClip {
    margin: 2rem 0;
  }

  .balloon {
    padding: 1rem;
  }

  .greenBaloon {
    left: 0rem;
  }

  .yellowBaloon {
    position: absolute;
    display: inline-block;
    left: 160px;
    top: 160px;
    z-index: -1;
  }

  .sectionThree {
    padding: 0 1rem;
    background-position-y: 50%;
  }

  .new-c {
    margin: 0;
    padding-bottom: 14rem;
  }

  .new-c1 {
    width: 100%;
    margin-top: 0rem;
    padding: 0 0.7rem;
  }

  .new-c2 {
    width: 100%;
    margin-top: 0rem;
    padding: 1rem;
  }

  .sl-bt-ps1 {
    position: absolute;
    left: 6%;
    top: 90%;
  }

  .sl-bt-ps2 {
    position: absolute;
    left: 8%;
    top: 80%;
  }
}

@media screen and (max-width: 360px) {
  .box1 {
    height: 13rem;
  }

  .box2 {
    height: 6rem;
    margin: 0.5rem;
    margin-top: 0;
  }

  .box3 {
    height: 6rem;
    margin: 0.5rem;
  }

  .sectionOne {
    height: auto;
  }

  .sectionTwo {
    height: auto;
    background-size: auto;
  }

  .slider-section {
    height: auto;
  }

  .slider-bgimgs {
    width: 100%;
    height: auto;
    position: relative;
  }

  .slider-txt {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: 50%;
  }

  .slider-img {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 50%;
    text-align: right;
  }

  .sl-h1 {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  .sl-h4 {
    font-size: 0.8rem;
  }

  .sl-p {
    font-size: 0.5rem;
  }

  .sl-img {
    height: 10rem;
  }

  .sl-img1 {
    height: 5rem;
  }

  .sl-dot-c {
    margin-bottom: 0rem;
  }

  .sl-dot-a1 {
    height: 1rem;
    margin: 0.3rem;
  }

  .sl-dot-a2 {
    height: 0.5rem;
    margin: 0.3rem;
  }

  .v-sec {
    display: flex;
    text-align: center;
  }

  .v-sec-1 {
    order: 2;
  }

  .v-sec-2 {
    order: 1;
  }

  .impacttext {
    padding: 0rem;
  }

  /* .impacttext1{
        width: 60%;
    } */

  .impact-img {
    padding: 0;
  }

  .headerimapactimg {
    height: 15rem;
  }

  .section-report {
    padding: 1rem;
  }

  .sectionThree {
    padding: 0 1rem;
    background-position-y: 50%;
  }

  .testi1 {
    font-size: 25px;
    margin-left: -1rem;
    margin-top: 0;
  }

  .testi2 {
    font-size: 14px;
    color: #0e2d39;
    /* width: 50%; */
    padding: 0;
    margin-top: 2rem;
    margin-left: -1rem;
  }

  .vote-section {
    padding: 1rem;
  }

  .vote-txt {
    padding: 1rem;
  }

  .vote-now {
    display: block;
    margin: auto;
    width: 100%;
  }

  .p3 {
    font-size: 35px;
  }

  /* .vote-txt2{
        width: 65%;
        margin-bottom: 0;
    } */

  /* .vote-now-img{
        text-align: right;
        position: absolute;
        top: 8rem;
        padding: 0;
    } */

  .vote-img {
    height: 9rem;
  }

  .newsletter-section {
    padding: 1rem;
  }

  .sectionFive {
    padding: 1rem;
    height: auto;
  }

  .subscribe {
    padding: 0;
  }

  .input-box {
    margin: 0.5rem 0;
  }

  .subscribe-btn {
    margin: 0;
    padding: 0.5rem 2rem;
  }

  .news-head {
    font-size: 30px;
    margin: 5rem 1rem 2rem 1rem;
  }

  .news-txt {
    padding: 0 1rem;
  }

  .link-side-img {
    height: 8rem;
    bottom: -15rem;
    position: absolute;
    left: 0rem;
    border-radius: 17%;
  }

  .p6 {
    font-size: 40px;
    margin-top: 1rem;
  }

  .link {
    margin: 0.5rem 0rem;
    height: 2.5rem;
    float: right;
  }

  .sectionSix {
    height: auto;
  }

  .news2 {
    margin-top: 1rem;
    margin-bottom: 10rem;
    flex-direction: column;
  }

  .news-card {
    /* width: 19.5rem; */
    height: 20rem;
    border-radius: 1rem;
    box-shadow: 0 0 5px 0 #999;
    margin: 0.5rem;
  }

  .myClip {
    margin: 2rem 0;
  }

  .balloon {
    padding: 0;
  }

  .greenBaloon {
    left: -1.5rem;
  }

  .yellowBaloon {
    position: absolute;
    display: inline-block;
    left: 160px;
    top: 140px;
    z-index: -1;
  }

  .getitnow {
    padding: 1rem 2rem;
    font-size: 3rem;
    margin: 0;
    font-weight: 700;
  }

  .new-c {
    margin: 0;
    padding-bottom: 14rem;
  }

  .new-c1 {
    width: 100%;
    margin-top: 8rem;
    padding: 0 0.7rem;
  }

  .new-c2 {
    width: 100%;
    margin-top: 0rem;
    padding: 1rem;
  }

  .sl-bt-ps1 {
    position: absolute;
    left: 6%;
    top: 90%;
  }

  .sl-bt-ps2 {
    position: absolute;
    left: 8%;
    top: 80%;
  }
}

.appheaderlink {
  margin: 0.5rem 0;
  margin-right: 0.5rem;
  height: 2.5rem;
  cursor: pointer;
}
