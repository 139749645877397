.how_to_use_parent_div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap:9px;
}

.how_to_use_inner_div {
    background-color: #E2E8F0;
    padding: 0.6rem 1.125rem;
    display: flex;
    flex-direction: column;
    gap: 1.125rem;
    padding-bottom: 1.25rem;
}

.step_text_div {
    padding: 8px 16px;
    border-bottom: 0.5px solid #94A3B8;
    text-align: center;
}

.step_text {
    color: #0E2D39;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.tap_text {
    font-size: 16.22px;
    color: #424242;
    font-weight: 500;
}
.tap_text_ktcl {
    font-size: 16.22px;
    color: #424242;
    font-weight: 500;
    text-align: center;
    margin: 0.5rem 0;
}

.info_div {
    border-left: 2px solid #FFC107;
    background: #FFF2CC;
    align-self: center;
    display: flex;
    border-radius: 4px;
    align-items: center;
    padding: 8px 17px;
    gap: 12px;
}

.info_text {
    color: #002661;
    font-size: 14px;
    font-weight: 600;
    line-height: 130%;
}

.or_text{
    text-align: center;
    color:#0E2D39;
    font-weight: 600;
    font-size: 20px;
    margin: 1rem 0;
}