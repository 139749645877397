.timeline {
    margin: 50px 60px;
    height: 4px;
    background-color: #ccc;
  }
  
  .timeline-progress {
      width: 100%;
      height: 150%;
      border-radius: 10rem;
      background-image: linear-gradient(to right, #25bdb3 , #fccc08);
  }
  
  .timeline-items {
      margin-left: -10px;
      margin-right: -10px;
      margin-top: -12px;
      display: flex;
      justify-content: space-around;
  }
  
  .timeline-item {
      position: relative;
      margin-top: -0.4rem;
      cursor: pointer;
  }
  
  .timeline-item::before {
      content: '';
      width: 30px;
      height: 30px;
      background-image: url("../../assets/dot.png");
      background-size: 100% 100%;
      display: block;
      border-radius: 100%;
  }
  
  .timeline-content {
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translateX(-50%);
      background-color: transparent;
      border-top: 2px solid #000;
      width: 100px;
      padding: 5px 10px;
      text-align: center;
      cursor: pointer;
  }

  .timeline-content-expand{
        position: absolute;
        top: 45px;
        left: 265%;
        transform: translateX(-43%);
        background-color: transparent;
        border-top: 2px solid #000;
        width: 12rem;
        padding: 5px 0px;
        text-align: left;
  }

  .timeline-content-up {
    position: absolute;
    top: -72px;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    border-bottom: 2px solid #000;
    width: 100px;
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
  }

  .timeline-content-up-expand {
    position: absolute;
    top: -185px;
    left: 250%;
    transform: translateX(-43%);
    background-color: transparent;
    border-bottom: 2px solid #000;
    width: 12rem;
    padding: 5px 0px;
    text-align: left;
    min-height: 10.6rem;
  }

  
  .timeline-content::before {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #fff;
      border-top: 2px solid;
      border-right: 2px solid;
      display: block;
      position: absolute;
      top: -10px;
      margin-left: -2px;
      left: 50%;
      transform: rotate(-45deg) translateX(-50%);
  }

.timeline-content-up::before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-bottom: 2px solid;
    border-left: 2px solid;
    display: block;
    position: absolute;
    top: 47px;
    margin-left: -2px;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%);
}


.timeline-content-expand::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-top: 2px solid;
    border-right: 2px solid;
    display: block;
    position: absolute;
    top: -10px;
    margin-left: -2px;
    left: 10%;
    transform: rotate(-45deg) translateX(-50%);
}


.timeline-content-up-expand::before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-bottom: 2px solid;
    border-left: 2px solid;
    display: block;
    position: absolute;
    top: 159px;
    margin-left: -2px;
    left: 11%;
    transform: rotate(-45deg) translateX(-50%);
}

  
  .timeline-item.active::before {
      background-color: orange;
  }
  
  .timeline-item.active .timeline-content .timeline-content-up {
      color: #fff;
      background-color: orange;
  }
  
  .timeline-item.active .timeline-content::before .timeline-content-up::before {
      background-color: orange;
  }