@font-face {   
  font-family: 'MazzardH';    
  src: url('fonts/MazzardH-Regular.otf');
}

@font-face {   
  font-family: 'MazzardB';    
  src: url('fonts/MazzardH-Medium.otf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'MazzardH',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'MazzardH'
    monospace;
}


*{
  font-family: "MazzardH";
}

.headb{
  font-family: "MazzardB";
}