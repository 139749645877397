.mediaOne {
    /* background-color: #0e2d39; */
    background-image: url("../../assets/headerbg2.png");
    background-size: contain;
    background-repeat: no-repeat;
    /* background-position: center; */
    /* height: 115vh; */
    width: 100%;
    padding: 1rem;
    position: relative;
  }
  
  .mediaTwo {
    /* height: 120vh; */
    width: 100%;
    padding: 3rem 1rem;
    background: #fff;
  }
  
  .mediaThree {
    /* height: 80vh; */
    width: 100%;
    padding: 7rem 11rem;
    background: #98c44f;
  }
  
  .mediaFour {
    /* height: 75vh; */
    background: #fff;
    padding: 0;
    border-radius: 1rem;
  }
  
  .top1 {
    padding: 6rem 7rem 2rem 7rem;
  }
  
  .b-card1 {
    /* background-image: url('../../assets/video.png');  */
    /* background-size: cover; 
      border-radius: 1rem 0 0 1rem; */
    /* height: 100%; */
    /* width: 100%; */
    /* margin: 0.1rem 1rem; */
    padding: 0;
  }
  
  .bl-1 {
    padding: 5rem;
  }
  
  /* .cs-2{
      margin-top: 15rem;
      display: flex;
  } */
  
  .c-1 {
    width: 18rem;
  }
  
  .rec-card {
    border-radius: 1rem;
    height: 9rem;
    width: 9rem;
    display: block;
    margin: auto;
  }
  
  .a-btn1 {
    position: absolute;
    left: -7%;
    top: 30%;
    color: #fff;
  }
  
  .a-btn2 {
    position: absolute;
    top: 30%;
    right: -7%;
    color: #fff;
  }
  
  .rec-p {
    /* margin-top: 0.5rem;
    font-size: 0.9rem;
    text-align: center;
    color: #fff; */
    margin-top: 0.5rem;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    font-weight: bold;
  }
  
  .nw-c {
    display: block;
    margin: auto;
    margin-bottom: 1.5rem;
  }
  
  .nw-img {
    height: 16rem;
    object-fit: cover;
  }
  
  .md-vdo {
    border-radius: 1rem 0 0 1rem;
    height: 100%;
  }

  .p-btm{
    padding-bottom: 25rem;
  }

  .doubt{
    top: -100%;
  }
  
  .five{
    padding: 1rem 4rem
  }
  
  .link1{
    margin: 1rem;
    float: left;
  }
  
  @media screen and (max-width: 1200px) {
    .bl-1 {
      padding: 2rem;
    }
  
    .vt-1 {
      padding: 0 8rem;
    }
  
    .mediaThree {
      padding-bottom: 12rem;
    }
  }
  
  @media screen and (max-width: 576px) {
    .s-top {
      margin-top: 4.5rem;
    }
  
    .w-1 {
      font-size: 30px;
    }
  
    .w-2 {
      font-size: 15px;
    }
  
    .top1 {
      padding: 1rem;
    }
  
    .mediaOne {
      height: auto;
      background-repeat: repeat;
    }
  
    .b-card1 {
      /* height: 40%; */
      width: 100%;
      /* margin: 0.1rem 1rem; */
      border-radius: 1rem;
    }
  
    .bl-1 {
      padding: 1rem 2rem;
    }
  
    .mediaTwo {
      height: auto;
    }
  
    .mediaThree {
      padding: 5rem 3rem;
      padding-bottom: 10rem;
    }
  
    .c-1 {
      width: 18rem;
      height: 20rem;
      border-radius: 1rem;
      box-shadow: 0 0 5px 0 #999;
      box-shadow: 0 0 5px 0 #999;
      margin: 0.5rem;
      display: block;
      margin: auto;
      margin-bottom: 1.5rem;
    }
  
    .cs-2 {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }
  
    .nh-1 {
      margin-top: 1rem;
    }
  
    .m3-p-h {
      font-size: 1.6rem;
      text-align: center;
    }
  
    .nw-img {
      height: auto;
    }
  
    .vt-1 {
      padding: 0;
    }
  
    .md-vdo {
      border-radius: 1rem 1rem 0 0;
    }
  
    .a-btn1 {
      left: -10%;
    }
  
    .a-btn2 {
      right: -10%;
    }

    .p-btm{
      padding-bottom: 35rem;
    }

    .doubt{
      top: -70%;
    }
    
    .five{
      padding: 1rem
    }
    
    .hd{
      margin-top: 0;
    }
    
    .link1{
      float: none;
    }
  }
  
  @media screen and (max-width: 360px) {
    .s-top {
      margin-top: 4.5rem;
    }
  
    .w-1 {
      font-size: 30px;
    }
  
    .w-2 {
      font-size: 15px;
    }
  
    .top1 {
      padding: 1rem;
    }
  
    .mediaOne {
      height: auto;
      background-repeat: repeat;
    }
  
    .mediaTwo {
      height: auto;
    }
  
    .mediaThree {
      padding: 3rem 2rem;
      padding-bottom: 10rem;
    }
  
    .m3-p-h {
      font-size: 1.6rem;
    }
  
    .b-card1 {
      /* height: 40%; */
      width: 100%;
      /* margin: 0.1rem 1rem; */
      border-radius: 1rem;
    }
  
    .bl-1 {
      padding: 1rem 2rem;
    }
  
    .c-1 {
      width: 18rem;
      height: 20rem;
      border-radius: 1rem;
      box-shadow: 0 0 5px 0 #999;
      margin: 0.5rem;
    }
  
    .cs-2 {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }
  
    .nh-1 {
      margin-top: 1rem;
    }
  
    .nw-img {
      height: auto;
    }
  
    .vt-1 {
      padding: 0;
    }
  
    .md-vdo {
      border-radius: 1rem 1rem 0 0;
    }
  
    .a-btn1 {
      left: -10%;
    }
  
    .a-btn2 {
      right: -10%;
    }

    .p-btm{
      padding-bottom: 35rem;
    }

    .doubt{
      top: -70%;
    }

    .five{
      padding: 1rem
    }
    
    .hd{
      margin-top: 0;
    }

    .link1{
      float: none;
    }
  }
  