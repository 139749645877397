
.link-section{
    background-image: url("../../assets/headerbg3.png");
    background-size: cover;
    background-repeat: no-repeat;
    /* height: 35vh; */
    width: 100%;
    /* padding: 2rem 4rem; */
    box-shadow: 1px 1px 5px 1px #ccc;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px 0 #737476;   
}

.vote-section{
    /* padding: 4rem 8rem; */
    padding-bottom: 2rem;
}

.leaderboard{
    /* height: 50vh; */
    padding: 3rem 4rem 8rem 4rem;
}

.lead-head{
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 1rem;
}

/* .lead-img{
    height: 6rem;
} */

.how-card{
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 2rem;
    box-shadow: 1px 1px 15px 1px #666;
}

.exp-txt{
    text-align: right;
    color: #fff;
    font-weight: 500;
    /* cursor: pointer; */
    z-index: 1;
}

.top-txt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:white;
    text-align: center;
    font-size: 20px;
}

.video{
    width: 100%; 
    object-fit: cover;
    height: 100vh; 
    position: relative;
}

.video-bg{
    width: 100%; 
    height: 100vh; 
    position: absolute; 
    top: 0; 
    opacity: 0.5; 
    background: #0e2d39;
}

.v-h1{
    font-size: 60px; 
    font-weight: 600;
}

.step-card{
    /* margin: 1rem; */
    padding: 1rem;
    cursor: pointer;
}

.s-in-c{
    background: rgb(255, 255, 255);
    width: 100%;
    border-radius: 1rem;
    margin: 0;
    padding: 1rem;
}

.step-card-h{
    margin: 2rem;
    padding: 1rem;
    box-shadow: 1px 1px 5px 1px #aaa;
    border-radius: 1rem;
}

.h-img{
    height: 30rem;
    /* width: fit-content; */
    display: block;
    margin: auto;
}

.hitwpcm {
    padding: 5rem;
}

.w-img{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    padding: 0
}

.high-card{
    box-shadow: inset 0 0 12px 12px white, inset 0 0 3px 2px white;
    background: linear-gradient(to right, #fccc08, #25bdb3);
    border-radius: 2rem;
}

@media screen and (max-width: 576px) { 
    .video{
        height: 65vh; 
    }
    
    .video-bg{
        height: 65vh; 
    }
    
    .v-h1{
        font-size: 50px; 
        font-weight: 600;
    }

    .leaderboard{
        padding: 0;
        padding-bottom: 10rem;
    }
    
    .lead-head{
        font-size: 35px;
        padding: 1rem;
    }

    .col-50{
        width: 50%;
    }

    .h-img{
        height: 15rem;
    }

    .hitwpcm {
        padding: 5rem 0;
    }
}

@media screen and (max-width: 360px) { 
    .video{
        height: 65vh; 
    }
    
    .video-bg{
        height: 65vh; 
    }
     
    .v-h1{
        font-size: 50px; 
        font-weight: 600;
    }

    .leaderboard{
        padding: 0;
        padding-bottom: 10rem;
    }

    .lead-head{
        font-size: 35px;
        padding: 1rem;
    }

    .col-50{
        width: 50%;
    }

    .h-img{
        height: 15rem;
    }

    .hitwpcm {
        padding: 5rem 0;
    }
}